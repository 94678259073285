<template>
    <div class="allocate">
        <el-card shadow="never">
            <template #header>
                <div class="clearfix" style="text-align:left">
                    <span>调拨管理</span>
                </div>
            </template>
            <div class="content">
                <el-input v-model="keyword" size="small" placeholder="请输入订单编号" style="width:250px;margin-right:20px;"></el-input>
                <el-select v-model="status" size="small" clearable style="margin-right:20px;">
                    <el-option value="0" label="等待调拨"></el-option>
                    <el-option value="1" label="已调拨"></el-option>
                    <el-option value="2" label="已关闭"></el-option>
                </el-select>
                <el-button size="small" type="primary" @click="init">查询</el-button>
            </div>
            <div class="content-main">
                <el-table :data="tabledata" border style="width: 100%; margin: 1em 0"
                          v-loading="load"
                          element-loading-spinner="el-icon-loading"
                          element-loading-text="加载中..."
                          size="small">
                    <el-table-column label="原订单编号" prop="OrderUID">
                        <template #default="scope">
                            <el-button type="primary" @click="GoToOrder(scope.row.OrderUID)">{{ scope.row.OrderUID }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="归属商户" prop="FromMerchant"></el-table-column>
                    <el-table-column label="订单状态">
                        审核不通过
                    </el-table-column>
                    <el-table-column label="审核时间" prop="AddDTime">
                        <template #default="scope">
                            <span>{{ formatDate(scope.row.AddDTime) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="原因" prop="Reason"></el-table-column>
                    <el-table-column label="关联名称" prop="AssociateName"></el-table-column>
                    <el-table-column label="调拨后订单编号" prop="NewOrderUID">
                        <template #default="scope">
                            <el-button type="primary" @click="GoToOrder(scope.row.NewOrderUID)">{{ scope.row.NewOrderUID }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="调拨后商户" prop="ToMerchant"></el-table-column>
                    <el-table-column label="调拨状态" prop="Status">
                        <template #default="scope">
                            <span v-if="scope.row.Status == 0">等待调拨</span>
                            <span v-if="scope.row.Status == 1">已调拨</span>
                            <span v-if="scope.row.Status == 2">已关闭</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template #default="scope">
                            <el-button v-if="scope.row.Status == 0" @click="SelectAllocate(scope.row.ID)" size="small" type="primary">手动调拨</el-button>
                            <el-button v-if="scope.row.Status == 0" @click="Reject(scope.row.ID)" size="small" type="primary">审核不通过</el-button>
                            <el-button v-if="scope.row.Status == 1 || scope.row.Status == 2" type="primary" size="small" @click="Log(scope.row.ID)">日志</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange"
                               @size-change="handleSizeChange"
                               :current-page="curpage"
                               :page-size="pagesize"
                               :page-sizes="[10, 20, 30, 50, 100]"
                               layout="sizes,total, prev, pager, next, jumper"
                               :total="totalcount"
                               :hide-on-single-page="false"
                               style="text-align:center"></el-pagination>
            </div>
        </el-card>
    </div>
    <el-dialog v-model="allocate" title="选择调拨商户" width="30%" center>
        <div style="display:flex;justify-content:center">
            <el-select v-model="merchantid" style="width: 400px">
                <el-option v-for="item in merchantlist" :key="item" :value="item.ID" :label="item.Merchant"></el-option>
            </el-select>
        </div>
        <template #footer>
            <el-button @click="save" type="primary">确定</el-button>
            <el-button @click="cancel">取消</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="reject" title="填写拒绝理由" width="30%" center>
        <el-input type="textarea" :rows="4" v-model="reason"></el-input>
        <template #footer>
            <el-button @click="savereject" type="primary">确定</el-button>
            <el-button @click="cancelreject">取消</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="alllog" title="调拨日志" center>
        <el-table :data="log">
            <el-table-column label="处理时间" prop="AddDTime">
                <template #default='scope'>
                    {{ formatDate(scope.row.AddDTime) }}
                </template>
            </el-table-column>
            <el-table-column label="操作人" prop="OpName"></el-table-column>
            <el-table-column label="处理结果">
                <template #default='scope'>
                    <span v-if="scope.row.Status == 1">已调拨</span>
                    <span v-if="scope.row.Status == 2">已关闭</span>
                </template>
            </el-table-column>
            <el-table-column label="备注" prop="Remark">
            </el-table-column>
        </el-table>
    </el-dialog>
</template>
<script>
import constant from "@/constant"
export default {
    data() {
        return {
            alllog: false,
            reject: false,
            allocate: false,
            tabledata: [],
            keyword: "",
            status: '',
            curpage: 1,
            pagesize: 10,
            totalcount: 0,
            merchantid: '',
            merchantlist: [],
            reason: '',
            curid: '',
            log: [],
        }
    },
    methods: {
        handleCurrentChange(val) {
            console.log(val);
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val) {
            console.log(val);
            this.pagesize = val
            this.init();
        },
        GoToOrder(orderuid) {
            this.axios.get(constant.getallorder, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    orderuid: orderuid
                }
            }).then((response) => {
                console.log(response.data);
                if (response.data.msg == "OK")
                    this.$router.push({ path: '/orderdetail', query: { id: response.data.orderid } });
                else if (response.data.msg == "NOORDER")
                    this.$message.error("查询不到订单");
            });
        },
        Log(id) {
            this.axios.get(constant.allocatelog, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data);
                this.log = response.data;
                this.alllog = true;
            });
        },
        Reject(id) {
            this.curid = id;
            this.reject = true;
        },
        cancelreject() {
            this.reason = "";
            this.curid = "";
            this.reject = false;
        },
        savereject() {
            this.axios.get(constant.rejectallocate, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    id: this.curid,
                    reason: this.reason
                }
            }).then((response) => {
                console.log(response.data);
                if (response.data == "OK") {
                    this.$message.success("操作成功");
                    this.$router.go(0);
                }
            });
        },
        SelectAllocate(id) {
            this.axios.get(constant.getassmerchant, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data);
                this.merchantlist = response.data;
                this.curid = id;
                this.allocate = true;
            });
        },
        init() {
            this.axios.get(constant.allocatelist, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                    keyword: this.keyword,
                    status: this.status
                }
            }).then((response) => {
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage * 1;
                this.totalcount = response.data.totalcount * 1;
            });
        },
        save() {
            if (!this.merchantid) {
                this.$message.error("必须选择一个调拨的商户");
                return false;
            }
            this.axios.get(constant.saveallocate, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    merchantid: this.merchantid,
                    id: this.curid
                }
            }).then((response) => {
                console.log(response.data);
                if (response.data == "OK") {
                    this.$message.success("操作成功");
                    this.allocate = false
                    this.$router.go(0);
                }
            });
        },
        cancel() {
            this.allocate = false;
        },
        formatDate(time) {
            let date = new Date(time * 1000);
            let year = date.getFullYear();
            let month = ("0" + (date.getMonth() + 1)).slice(-2);
            let day = ("0" + date.getDate()).slice(-2);
            let hour = ("0" + date.getHours()).slice(-2);
            let minute = ("0" + date.getMinutes()).slice(-2);
            let second = ("0" + date.getSeconds()).slice(-2);
            return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        }
    },
    created: function () {
        this.init();
    }
}
</script>
<style scoped></style>